import { mapState, mapGetters, mapActions } from "vuex";

const data = function () {
  return {
    provinceList: [], //省
    cityList: [], //市
    areaList: [], //区
    streetList: [], //街道
    communityList: [], //社区
    // 分页的参数配置
    page: {
      totalResult: 0,
      currentPage: 0,
      pageSize: 10,
      align: "left",
      pageSizes: [10, 20, 50, 100, 200, 500, 1000],
      perfect: true
    },
  }
}
const methods = {
  ...mapActions([
    'getProvincesServe',
    'getCitysServe',
    'getAreasServe',
    'getStreetsServe',
    'getCommunitysServe',
    'getRegionServe'
  ]),
  // 获取省
  getProvincesList() {
    this.getProvincesServe().then(res => {
      if (res.code == 200) {
        this.provinceList = res.data
      }
    })
  },
  provinceChange(val) {
    let data = val.value ? val.value : val
    if (data) {
      this.getCitysList(data)
    }
  },
  // 获取市区
  getCitysList(val) {
    let params = {
      parenName: val
    }
    this.getCitysServe(params).then(res => {
      if (res.code == 200) {
        this.cityList = res.data
      }
    })
  },
  cityChange(val) {
    let data = val.value ? val.value : val
    if (data) {
      this.getAreasList(data)
    }
  },
  // 获取区县
  getAreasList(val) {
    let params = {
      parenName: val
    }
    this.getAreasServe(params).then(res => {
      if (res.code == 200) {
        this.areaList = res.data
      }
    })
  },
  areaChange(val) {
    let data = val.value ? val.value : val
    if (data) {
      this.getStreetsList(data)
    }
  },
  // 获取街道
  getStreetsList(val) {
    let params = {
      parenName: val
    }
    this.getStreetsServe(params).then(res => {
      if (res.code == 200) {
        this.streetList = res.data
      }
    })
  },
  streetChange(val) {
    let data = val.value ? val.value : val
    if (data) {
      this.getCommunitysList(data)
    }
  },
  // 获取社区
  getCommunitysList(val) {
    let params = {
      parenName: val
    }
    this.getCommunitysServe(params).then(res => {
      if (res.code == 200) {
        this.communityList = res.data
      }
    })
  },
}
export default {
  methods,
  data
}