<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button v-if="seachStatus" status="my-orange" @click="showSearch(false)">隐藏查询</vxe-button>
        <!-- <vxe-button status="my-orange" icon="fa fa-search" @click="showSearch(true)" v-if="!seachStatus">查询</vxe-button> -->
        <vxe-button v-permission="'AddPerson'" status="my-orange" icon="fa fa-plus" @click="selectPersonId()">添加
        </vxe-button>
        <vxe-button v-permission="'PersonManageImportButton'" status="my-orange" icon="fa fa-plus" @click="importData">
          导入
        </vxe-button>
        <vxe-button v-permission="'PersonManageExportButton'" status="my-orange" icon="fa fa-plus" @click="exportData">
          导出
        </vxe-button>
<!--        <vxe-button status="my-orange" icon="fa fa-plus" @click="exportElderlyOriented">-->
<!--          适老化改造导出-->
<!--        </vxe-button>-->
      </template>
    </vxe-toolbar>
    <div class="scroll-box">
      <div class="search">
        <vxe-form
            title-align="right"
            title-width="120"
            title-colon
            :data="searchData"
            @submit="searchEvent"
            @reset="searchReset"
        >
          <vxe-form-item title="姓名" span="6">
            <vxe-input v-model="searchData.name" placeholder="请输入姓名" clearable style="width: 150px"/>
          </vxe-form-item>
          <vxe-form-item title="性别" span="6" title-width="80">
            <vxe-select v-model="searchData.sex" placeholder="请选择" style="width: 84px">
              <vxe-option label="男" value="男"/>
              <vxe-option label="女" value="女"/>
            </vxe-select>
          </vxe-form-item>
          <vxe-form-item title="年龄" span="6" title-width="80" style="margin-left: -100px">
            <vxe-input v-model="searchData.ageFrom" placeholder="请输入起始年龄" style="width: 150px"/>
          </vxe-form-item>
          <vxe-form-item :span="1" title-width="0" style="text-align: center; margin-left: -40px">
            <label for="">至</label>
          </vxe-form-item>
          <vxe-form-item title-width="0" span="5">
            <vxe-input v-model="searchData.ageTo" placeholder="请输入终止年龄" style="width: 150px"/>
          </vxe-form-item>

          <vxe-form-item title="身份证号码" span="9">
            <vxe-input v-model="searchData.idNumber" placeholder="请输入您的身份证号码"/>
          </vxe-form-item>

          <vxe-form-item title="手机号" span="9">
            <vxe-input v-model="searchData.contract" placeholder="请输入手机号"/>
          </vxe-form-item>
          <vxe-form-item title="" span="6"/>

          <vxe-form-item title="残疾等级" span="6">
            <vxe-select v-model="searchData.disability" placeholder="请选择残疾等级" style="width: 150px">
              <vxe-option
                  v-for="item in disabilityList"
                  :key="item.dictPidVal"
                  :label="item.dictDesc"
                  :value="item.dictPidVal"
              />
            </vxe-select>
          </vxe-form-item>
          <vxe-form-item title="能力评估结果" span="6">
            <vxe-select v-model="searchData.capabilityAssessResult" placeholder="请选能力评估结果" style="width: 150px">
              <vxe-option
                  v-for="item in abiEvalRstList"
                  :key="item.dictPidVal"
                  :label="item.dictDesc"
                  :value="item.dictPidVal"
              />
            </vxe-select>
          </vxe-form-item>
          <vxe-form-item title="人员级别" span="6" style="margin-left: -20px">
            <vxe-select v-model="searchData.personLevel" placeholder="请选择人员级别" style="width: 150px">
              <vxe-option
                  v-for="item in personLevelList"
                  :key="item.dictPidVal"
                  :label="item.dictDesc"
                  :value="item.dictPidVal"
              />
            </vxe-select>
          </vxe-form-item>
          <vxe-form-item title="人员类别" span="24">
            <vxe-checkbox-group v-model="searchData.personType">
              <vxe-checkbox v-for="item in personCategoryList" :key="item.dictPidVal" :label="item.dictPidVal">
                {{ item.dictDesc }}
              </vxe-checkbox>
            </vxe-checkbox-group>
          </vxe-form-item>
          <vxe-form-item title="项目服务" span="24">
            <vxe-checkbox-group v-model="searchData.projectService" @change="changeProjectService">
              <vxe-checkbox v-for="item in projectServiceList" :key="item.dictPidVal" :label="item.dictPidVal">
                {{ item.dictDesc }}
              </vxe-checkbox>
            </vxe-checkbox-group>
          </vxe-form-item>
          <vxe-form-item title="政府项目服务类别" span="24" v-if="searchData.projectService.includes('163_101')">
            <vxe-checkbox-group v-model="searchData.zfProjectServiceCategory">
              <vxe-checkbox v-for="item in zfProjectServiceCategoryList" :key="item.dictPidVal"
                            :label="item.dictPidVal">
                {{ item.dictDesc }}
              </vxe-checkbox>
            </vxe-checkbox-group>
          </vxe-form-item>
          <vxe-form-item title="居住状况" span="24">
            <vxe-checkbox-group v-model="searchData.living">
              <vxe-checkbox v-for="item in livingList" :key="item.dictPidVal" :label="item.dictPidVal">
                {{ item.dictDesc }}
              </vxe-checkbox>
            </vxe-checkbox-group>
          </vxe-form-item>
          <vxe-form-item title="残疾类别" span="24">
            <vxe-checkbox-group v-model="searchData.disabilityCategory">
              <vxe-checkbox v-for="item in disabilityCategoryList" :key="item.dictPidVal" :label="item.dictPidVal">
                {{ item.dictDesc }}
              </vxe-checkbox>
            </vxe-checkbox-group>
          </vxe-form-item>
          <vxe-form-item title="综合服务" span="24">
            <vxe-checkbox-group v-model="searchData.genService">
              <vxe-checkbox v-for="item in genServiceList" :key="item.dictPidVal" :label="item.dictPidVal">
                {{ item.dictDesc }}
              </vxe-checkbox>
            </vxe-checkbox-group>
          </vxe-form-item>
          <vxe-form-item title="当前状态" span="12">
            <vxe-select v-model="searchData.currentStatus" placeholder="请选择当前状态">
              <vxe-option
                  v-for="item in currentStatusList"
                  :key="item.dictPidVal"
                  :label="item.dictDesc"
                  :value="item.dictPidVal"
              />
            </vxe-select>
          </vxe-form-item>

          <vxe-form-item title="常住地址" span="18" class="area-form">
            <RegionForm ref="usualTenant" v-model="searchData.usualTenant"/>
          </vxe-form-item>

          <vxe-form-item title="常住详细地址" span="18">
            <vxe-input v-model="searchData.usualAddress" placeholder="请输入您的常住地址"/>
          </vxe-form-item>

          <vxe-form-item align="center" span="24">
            <vxe-button type="submit" status="my-orange" icon="fa fa-check">查询</vxe-button>
            <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
          </vxe-form-item>
        </vxe-form>
      </div>
      <div class="table">
        <vxe-grid
            border
            stripe
            resizable
            auto-resize
            :columns="tableColumn"
            :data="tableData"
            :loading="loading"
            header-align="center"
        >
          <template v-slot:personName="{ row }">
            <p class="link-a" @click="goPage('detail', row)">{{ row.personName }}</p>
          </template>
          <template v-slot:operation="{ row }">
            <vxe-button v-if="!row.ismore" v-permission="'SetPersonMonitor'" size="mini" type="text" status="primary" @click="openMoniter('edit', row)">
             <img src="@/assets/images/monitor.png" alt=""/>
            </vxe-button>
            <vxe-button v-if="!row.ismore" v-permission="'AddPerson'" type="text" status="primary" @click="goPage('edit', row)">修改
            </vxe-button>
            <vxe-button
                v-if="!row.ismore"
                type="text"
                icon="el-icon-plus"
                size="mini"
                @click="row.ismore = true"
            />
            <vxe-button v-if="row.ismore" v-permission="'PersonManageDeleteButton'" type="text" status="primary" @click="remove(row.id)">
              删除
            </vxe-button>
            <vxe-button
                v-if="row.ismore"
                type="text"
                size="mini"
                icon="el-icon-minus"
                @click="row.ismore = false"
            />
          </template>
        </vxe-grid>
      </div>
      <!-- 分页 -->
      <div class="page">
        <vxe-pager
            :current-page.sync="tablePage.currentPage"
            :page-size.sync="tablePage.pageSize"
            :page-sizes="tablePage.pageSizes"
            :total="tablePage.totalResult"
            :layouts="layouts"
            @page-change="pageChange"
        />
      </div>
    </div>

    <el-dialog
        v-loading="uploadLoading"
        element-loading-text="上传中，请勿关闭窗口或切换页面！"
        title="导入人员"
        :visible.sync="importVisible"
        :close-on-click-modal="false"
        width="35%"
    >
      <el-upload class="upload-demo" accept=".xlsx, .xls" action="" :before-upload="handleUpload">
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
      <div class="file-list">
        <p>{{ fileName }}</p>
      </div>
      <div>
        <p class="import-old-person-file">请选择文件小于5M且最多1000条数据的Excel</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="importVisible = false">取 消</el-button>
        <el-button type="primary" :disabled="btndisabled" @click="importSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 查询老人信息弹框 -->
    <el-dialog title="选择人员" :visible.sync="personVisible" width="68%" :close-on-click-modal="false">
      <div>
        <vxe-form
            title-align="right"
            title-width="120"
            title-colon
            :data="searchPersonData"
            @submit="searchOldPerson"
            @reset="searchPersonReset"
        >
          <vxe-form-item title="老人姓名" span="12">
            <vxe-input v-model="searchPersonData.personName" placeholder="请输入老人姓名" clearable/>
          </vxe-form-item>
          <vxe-form-item title="联系电话" span="12">
            <vxe-input v-model="searchPersonData.tel" placeholder="请输入联系电话" clearable/>
          </vxe-form-item>
          <vxe-form-item title="身份证号" span="12">
            <vxe-input v-model="searchPersonData.idNumber" placeholder="请输入身份证号" clearable/>
          </vxe-form-item>
          <vxe-form-item align="center" span="12">
            <vxe-button type="submit" status="my-orange" icon="fa fa-check">搜索</vxe-button>
            <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
          </vxe-form-item>
        </vxe-form>
        <div style="margin-top: 10px">
          <el-table ref="singleTable" :data="tablePersonData" highlight-current-row border size="medium"
                    :height="height">
            <el-table-column label="" width="40">
              <template slot-scope="scope">
                <el-radio
                    v-model="radio"
                    :label="scope.$index"
                    style="color: #fff; padding-left: 10px"
                    @change.native="getCurrentRow(scope.row)"
                />
              </template>
            </el-table-column>
            <el-table-column prop="name" width="100" label="姓名" align="center"/>
            <el-table-column prop="sex" label="性别" width="60" align="center"/>
            <el-table-column prop="idNum" label="身份证" width="180" align="center"/>
            <el-table-column prop="tel" label="联系方式" width="120" align="center"/>
            <el-table-column prop="address" label="地址" align="center"/>
          </el-table>
          <div style="text-align: right; margin-top: 10px">
            <el-pagination
                background
                :current-page="tablePersonPage.currentPage"
                :page-sizes="[5, 10]"
                :page-size="tablePersonPage.pageSize"
                layout="total,jumper,prev, pager, next,sizes"
                :total="tablePersonPage.totalResult"
                @size-change="handlePersonSizeChange"
                @current-change="handlePersonCurrentChange"
            />
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button v-if="showAddOrCopyBnt" type="primary" @click="copy()">{{ buttonText }}</el-button>
      </div>
    </el-dialog>
    <el-dialog custom-class="moniter" title="监控" :visible.sync="moniterVisible" width="35%">
      <moniter :id="moniterId" ref="moniter" />
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import api from '@/store/API/api'
import axios from 'axios'
import crudAreaMixin from '@/mixins/crudAreaMixin.js'
import RegionForm from '@/components/RegionForm.vue'
import moniter from './components/moniter'

export default {
  components: { RegionForm, moniter},
  mixins: [crudAreaMixin],
  data() {
    return {
      moniterVisible: false,
      moniterId: '',
      uploadLoading: false,
      loading: false,
      seachStatus: false,
      personVisible: false,
      importVisible: false,
      showAddOrCopyBnt: false,
      searchData: {
        name: '',
        personType: [],
        living: [],
        disabilityCategory: [],
        genService: [],
        usualTenant: [],
        projectService: [],
        zfProjectServiceCategory: []
      },
      fileName: '',
      fileData: null,
      // 分页的参数配置
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true

      },
      btndisabled: false,
      // 老人信息分页配置
      radio: '',
      height: 240,
      tablePersonPage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 5,
        align: 'left',
        pageSizes: [5, 10, 20, 50, 100, 500, 1000],
        perfect: true
      },
      searchPersonData: {
        personName: '',
        idNumber: '',
        tel: ''
      },
      tablePersonData: [],
      selectRow: {},
      // 变量
      disabilityList: [],
      abiEvalRstList: [],
      personCategoryList: [],
      personLevelList: [],
      livingList: [],
      currentStatusList: [],
      disabilityCategoryList: [],
      genServiceList: [],
      projectServiceList: [],
      zfProjectServiceCategoryList: [],
      tableColumn: [
        {
          type: 'seq',
          title: '序号',
          width: 60,
          align: 'center'
        },
        {
          field: 'personName',
          title: '姓名',
          showOverflow: true,
          align: 'center',
          minWidth: '100',
          slots: {
            default: 'personName'
          }
        },
        {
          field: 'sex',
          title: '性别',
          showOverflow: true,
          align: 'center',
          minWidth: '80'
        },
        {
          field: 'age',
          title: '年龄',
          showOverflow: true,
          align: 'center',
          minWidth: '80'
        },
        {
          field: 'serviceCount',
          title: '服务次数',
          showOverflow: true,
          align: 'center',
          minWidth: '100'
        },
        {
          field: 'address',
          title: '住址',
          showOverflow: true,
          align: 'center',
          minWidth: '200'
        },
        {
          field: 'idNum',
          title: '身份证号',
          showOverflow: true,
          align: 'center',
          minWidth: '200'
        },
        {
          field: 'contract',
          title: '联系方式',
          showOverflow: true,
          align: 'center',
          minWidth: '160'
        },
        {
          field: 'personTypeName',
          title: '人员类别',
          showOverflow: true,
          align: 'center',
          minWidth: '200'
        },
        {
          title: '操作',
          minWidth: '140',
          showOverflow: true,
          slots: {
            default: 'operation'
          }
        }
      ],
      tableData: [],
      buttonText: '新增'
    }
  },
  computed: {
    ...mapState(['layouts']),
    ...mapGetters(['seqId'])
  },
  watch: {
    seqId() {
      // this.getLists()
    }
  },
  created() {
    this.initView()
    this.getDicList()
    this.getProvincesList()
    this.$nextTick(() => {
      this.$refs.usualTenant.initRegion()
    })
    // this.getConsConsultant()
  },
  methods: {
    ...mapActions([
      'getHomePersonListServe',
      'delHomeOldPersonServe',
      'exportHomeOldPersonServe',
      'importHomeOldPersonServe',
      'getSysDictServe',
      'searchPerson',
      'copyOldPerson'
    ]),
    initView() {
      // this.getTeamList();
      // this.getCategoryList();
      this.getLists()
    },
    getDicList() {
      this.getSysDictList('cityCategory', 'cityCategoryList') // 城乡类别
      this.getSysDictList('educat', 'educatList') // 文化程度
      this.getSysDictList('preJob', 'preJobList') // 退休前职业
      this.getSysDictList('abiEvalRst', 'abiEvalRstList') // 能力评估结果
      this.getSysDictList('children', 'childrenList') // 子女状况
      this.getSysDictList('chronic', 'chronicList') // 慢性病
      this.getSysDictList('currentStatus', 'currentStatusList') // 当前状态
      this.getSysDictList('disability', 'disabilityList') // 残疾类别
      this.getSysDictList('entertain', 'entertainList') // 精神文化休闲娱乐
      this.getSysDictList('genService', 'genServiceList') // 综合服务
      this.getSysDictList('helpClean', 'helpCleanList') // 助洁
      this.getSysDictList('helpBath', 'helpBathList') // 助浴
      this.getSysDictList('helpDoct', 'helpDoctorList') // 助医
      this.getSysDictList('helpMerg', 'helpRescueList') // 助急
      this.getSysDictList('helpWork', 'helpWorkList') // 助行
      this.getSysDictList('housNature', 'housNatureList') // 住房性质
      this.getSysDictList('incomeSource', 'incomeSourceList') // 经济来源
      this.getSysDictList('insure', 'insureList') // 参保情况
      this.getSysDictList('living', 'livingList') // 居住情况
      this.getSysDictList('maritalStatus', 'maritalStatusList') // 婚姻情况
      this.getSysDictList('missedReason', 'missedReasonList') // 未接听原因
      this.getSysDictList('serviceEval', 'serviceEvalList') // 养老服务需求评估
      this.getSysDictList('permsionMethod', 'permsionMethodList') // 养老方式
      this.getSysDictList('personLevel', 'personLevelList') // 人员级别
      this.getSysDictList('personCategory', 'personCategoryList') // 人员类别
      this.getSysDictList('poliStatus', 'poliStatusList') // 政治面貌
      this.getSysDictList('comfort', 'comfortList') // 心理慰藉
      this.getSysDictList('selfCareAbiy', 'selfCareAbiyList') // 生活自理能力
      this.getSysDictList('doctType', 'doctTypeList') // 就医方式
      this.getSysDictList('disabilityCategory', 'disabilityCategoryList') // 残疾类别
      this.getSysDictList('genService', 'genServiceList') // 综合服务
      this.getSysDictList('projectService', 'projectServiceList') // 项目服务
      this.getSysDictList('zfProjectServiceCategory', 'zfProjectServiceCategoryList') // 政府项目服务类别
    },
    // 获取数据字典
    getSysDictList(code, codeList) {
      const params = {
        typeCode: code
      }
      this.getSysDictServe(params).then((res) => {
        if (res.code === 200) {
          this[codeList] = res.data
        }
      })
    },

    changeProjectService() {
      if (!this.searchData.projectService.includes('163_101')) {
        this.searchData.zfProjectServiceCategory = []
      }
    },
    // 是否显示查询功能
    showSearch(bool) {
      this.seachStatus = bool
    },
    goPage(type, itemData) {
      if (type === 'detail') {
        this.$router.push({
          name: 'AddPerson',
          params: {
            type: type,
            id: itemData.id,
            name: itemData.name
          },
          query: {
            oldPersonId: itemData.id,
            name: itemData.name
          }
        })
      } else {
        this.$router.push({
          name: 'AddPerson',
          params: {
            type: type,
            id: type === 'add' ? 'add' : itemData.id
          },
          query: {
            item: type === 'add' ? 'add' : JSON.stringify(itemData)
          }
        })
      }
    },
    getLists() {
      this.loading = true
      const usualAddress = this.getUsualAddress()

      this.getHomePersonListServe({
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          ageFrom: this.searchData.ageFrom,
          ageTo: this.searchData.ageTo,
          capabilityAssessResult: this.searchData.capabilityAssessResult,
          currentStatus: this.searchData.currentStatus,
          disability: this.searchData.disability,
          idNumber: this.searchData.idNumber,
          living: this.searchData.living.length ? this.searchData.living : null,
          disabilityCategory: this.searchData.disabilityCategory.length ? this.searchData.disabilityCategory : null,
          genService: this.searchData.genService.length ? this.searchData.genService : null,
          personLevel: this.searchData.personLevel,
          personName: this.searchData.name,
          personType: this.searchData.personType.length ? this.searchData.personType : null,
          projectService: this.searchData.projectService.length ? this.searchData.projectService : null,
          zfProjectServiceCategory: this.searchData.zfProjectServiceCategory.length ? this.searchData.zfProjectServiceCategory : null,
          sex: this.searchData.sex,
          usualAddress: this.searchData.usualAddress,
          usualProvince: usualAddress.usualProvince,
          usualCity: usualAddress.usualCity,
          usualArea: usualAddress.usualArea,
          usualStreet: usualAddress.usualStreet,
          usualCommunity: usualAddress.usualCommunity,
          contract: this.searchData.contract,
          hasTenant: true
        }
      }).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.records
          this.tablePage.totalResult = +res.data.total
          for (let i = 0; i < this.tableData.length; i++) {
            this.$set(this.tableData[i], 'ismore', false)
          }
        }
        this.loading = false
      })
    },
    handleUpload(file) {
      const suffix = file.name.split('.')[1]
      if (!['xlsx', 'xls'].includes(suffix)) {
        this.$XModal.message({
          message: '文件格式不正确',
          status: 'warning'
        })
        return false
      }
      const realFilesize = file.size / 1024 / 1023
      if (realFilesize > 5) {
        this.$XModal.message({
          message: '你选择的文件大于5M',
          status: 'warning'
        })
        return false
      }

      this.fileData = file
      this.fileName = file.name
      return false
    },
    // 导入功能
    importData() {
      this.importVisible = true
    },
    importSubmit() {
      if (this.fileData) {
        this.btndisabled = true
        const formData = new FormData()

        formData.append('excelFile', this.fileData)
        // 上传中
        this.uploadLoading = true

        let url = process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_BASEURL
        url += api.importHomeOldPerson
        axios({
          method: 'post',
          data: formData,
          url,
          timeout: 300000,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: sessionStorage.token
          }
        }).then((res) => {
          this.uploadLoading = false
          this.btndisabled = false
          if (res.data.code === 200) {
            this.uploadLoading = false
            this.$message.success('导入成功')
            this.importVisible = false
            this.getLists()
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error',
              showClose: true,
              duration: 0

            })
          }
        })
      } else {
        this.$XModal.message({
          message: '请上传模板文件',
          status: 'warning'
        })
      }
    },
    // 导出功能
    exportData() {
      const usualAddress = this.getUsualAddress()
      let url = process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_BASEURL
      url += api.exportHomeOldPerson
      const myObj = {
        method: 'post',
        url,
        fileName: '人员管理',
        params: {
          current: 1,
          size: 10,
          param: {
            ageFrom: this.searchData.ageFrom,
            ageTo: this.searchData.ageTo,
            capabilityAssessResult: this.searchData.capabilityAssessResult,
            currentStatus: this.searchData.currentStatus,
            disability: this.searchData.disability,
            idNumber: this.searchData.idNumber,
            living: this.searchData.living.length ? this.searchData.living : null,
            disabilityCategory: this.searchData.disabilityCategory.length ? this.searchData.disabilityCategory : null,
            genService: this.searchData.genService.length ? this.searchData.genService : null,
            personLevel: this.searchData.personLevel,
            personName: this.searchData.name,
            personType: this.searchData.personType.length ? this.searchData.personType : null,
            sex: this.searchData.sex,
            usualAddress: this.searchData.usualAddress,
            usualProvince: usualAddress.usualProvince,
            usualCity: usualAddress.usualCity,
            usualArea: usualAddress.usualArea,
            usualStreet: usualAddress.usualStreet,
            usualCommunity: usualAddress.usualCommunity,
            projectService: this.searchData.projectService.length ? this.searchData.projectService : null,
            zfProjectServiceCategory: this.searchData.zfProjectServiceCategory.length ? this.searchData.zfProjectServiceCategory : null,
            contract: this.searchData.contract,
            hasTenant: true
          }
          // param: {
          //   personName: this.searchData.name,
          // },
        }
      }
      this.$tools.exportMethod(myObj)
    },

    // 导出功能
    exportElderlyOriented() {
      let url = process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_BASEURL
      url += api.exportElderlyOriented
      const myObj = {
        method: 'get',
        url,
        fileName: '适老化改造',
        params: {}
      }
      this.$tools.exportMethodNoFileName(myObj)
    },
    // 分页功能
    pageChange(item) {
      if (item.type === 'size') {
        this.tablePage.currentPage = 1
      } else {
        this.tablePage.currentPage = item.currentPage
      }
      this.getLists()
    },
    searchEvent() {
      this.seachStatus = false
      this.getLists()
    },
    searchReset() {
      this.searchData = {
        living: [],
        personType: [],
        disabilityCategory: [],
        genService: [],
        usualTenant: []
      }
      this.getLists()
    },
    remove(id) {
      this.$XModal.confirm('您确定要删除该数据?').then((type) => {
        if (type === 'confirm') {
          console.log('执行删除操作')
          this.delete(id)
        }
      })
    },
    delete(id) {
      this.delHomeOldPersonServe({
        id: id
      }).then((res) => {
        if (res.code === 200) {
          this.$XModal.message({
            message: '删除成功',
            status: 'success'
          })
          this.getLists()
        }
        this.loading = false
      })
    },
    // 查询老人
    selectPersonId() {
      this.personVisible = true
    },
    searchOldPerson() {
      this.getConsConsultant()
    },
    searchPersonReset() {
      this.searchPersonData = {}
      this.showAddOrCopyBnt = false
      this.getConsConsultant()
    },
    handlePersonSizeChange: function (size) {
      this.tablePersonPage.pageSize = size
      this.getConsConsultant()
    },
    // 控制页面的切换
    handlePersonCurrentChange: function (currentPage) {
      this.tablePersonPage.currentPage = currentPage
      this.getConsConsultant()
    },
    getConsConsultant() {
      const params = {
        current: this.tablePersonPage.currentPage,
        size: this.tablePersonPage.pageSize,
        param: {
          personName: this.searchPersonData.personName,
          idNumber: this.searchPersonData.idNumber,
          tel: this.searchPersonData.tel,
          seachCmn: true
        }
      }
      if (this.searchPersonData.personName === '' && this.searchPersonData.idNumber === '' && this.searchPersonData.tel === '') {
        this.$message.warning('搜索条件不能为空')
        return
      }
      const server = 'searchPerson'
      this[server](params).then((res) => {
        if (res.code === 200) {
          this.tablePersonData = res.data.records
          this.tablePersonPage.totalResult = +res.data.total
          this.buttonText = this.tablePersonData.length > 0 ? '复制' : '新增'
          this.showAddOrCopyBnt = true
        }
      })
    },
    getCurrentRow(row) {
      this.selectRow = row
    },
    cancel() {
      this.personVisible = false
    },
    copy() {
      if (this.tablePersonData.length === 0) {
        this.goPage('add')
      } else {
        const params = {
          pubOldPersonId: null
        }
        if (this.selectRow) {
          params.pubOldPersonId = this.selectRow.id
        }
        const server = 'copyOldPerson'
        this[server](params).then((res) => {
          if (res.code === 200) {
            this.goPage('edit', this.selectRow)
          } else {
            this.$XModal.message({
              message: res.msg,
              status: 'error'
            })
          }
        })
      }
    },
    getUsualAddress() {
      const address = {
        usualProvince: '',
        usualCity: '',
        usualArea: '',
        usualStreet: '',
        usualCommunity: ''
      }
      if (this.$refs.usualTenant) {
        const usualTenant = this.$refs.usualTenant.$refs.tenant
        if (usualTenant.inputValue) {
          const addressList = usualTenant.inputValue.split(usualTenant.separator)
          if (addressList.length) {
            address['usualProvince'] = addressList[0] || ''
            address['usualCity'] = addressList[1] || ''
            address['usualArea'] = addressList[2] || ''
            address['usualStreet'] = addressList[3] || ''
            address['usualCommunity'] = addressList[4] || ''
          }
        }
      }

      return address
    },
    openMoniter(type, itemData) {
      this.moniterVisible = true
      this.moniterId = itemData.id
      this.$nextTick(() => {
        this.$refs.moniter.queryList()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .scroll-box {
    flex: 1;
    overflow: auto;
  }

  .datetime {
    display: flex;
    align-items: center;

    span {
      margin: 0 3px;
    }
  }
}

.sign i {
  margin-right: 3px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.import-old-person-file {
  padding-top: 30px;
  font-size: 20px;
  font-weight: bolder;
  color: red;
}
</style>
